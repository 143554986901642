import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


export default function Index() 
{
  return(
  <>
    <App />
   </>

)

}


ReactDOM.render(<Index/>, document.getElementById('root'))


